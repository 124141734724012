import { useNavigate, useSearchParams } from 'react-router-dom';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useRef, useState, useEffect } from 'react';

import Color from './views/Color';
import Glazing from './views/Glazing';
import Size from './views/Size';

import ReactLoading from 'react-loading';

import { BsNodePlus } from 'react-icons/bs';
import { BsFillPaletteFill } from 'react-icons/bs';
import { BsMicrosoft } from 'react-icons/bs';

import { BsFillCartPlusFill } from 'react-icons/bs';

import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';
import { API_URL,DEFAULT_COLOR_SLUG,DEFAULT_COLOR_NAME,DEFAULT_GLAZING_NAME,DEFAULT_GLAZING_SLUG,DEFAULT_GLAZING_THICKNESS } from './Constant';


function Home() {
	const navigate = useNavigate();

	const [openColor, setOpenColor] = useState(false);
	const [openSize, setOpenSize] = useState(false);
	const [openGlazing, setOpenGlazing] = useState(false);
	const [canvasImage, setCanvasImage] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [techaspect,setTechaspect] = useState();

	// model form state here
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({
		name: '',
		mobileNumber: '',
		email: '',
	  });
	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	const submitForm = (event) => {
		event.preventDefault();
		
		const newErrors = {
		  name: name ? '' : 'Name is required.',
		  mobileNumber: validateMobileNumber(mobileNumber) ? '' : 'Invalid mobile number.',
		  email: validateEmail(email) ? '' : 'Invalid email address.',
		};
		let userInfo = {};
		if (Object.values(newErrors).every(error => error === '')) {
			userInfo['name'] = name;
			userInfo['mobileNumber'] = mobileNumber;
			userInfo['email'] = email;
			localStorage.setItem('userInfo',JSON.stringify(userInfo));
			setOpen(false);
			setIsLoading(false);
			fetch(API_URL + `get-user-info`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(userInfo),
			})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Network response was not ok.');
			})
			.then(data => {
				if (data) {
					navigate('/cart');
				} else {
					throw new Error('Empty response from server');
				}
			})
			.catch(error => {
				console.error('There was a problem with your fetch operation:', error);
			});
		} else {
		  setErrors(newErrors);
		}
	  };

	  const returnNullIfEmpty = (value) =>{
		if(value == undefined || value == ''){
			return '';
		}
		else{
			return value;
		}
	  } 

	  const validateMobileNumber = (mobileNumber) => {
		// Define a regular expression for a valid mobile number format
		const mobileNumberPattern = /^[0-9]{10}$/; // Assumes a 10-digit mobile number, adjust as needed
	  
		return mobileNumberPattern.test(mobileNumber);
	  };

	const [preLoadObject, setPreLoadObject] = useState();
	const canvasRef = useRef();
	const [colorUp, setColorUp] = useState(0);
	const loadDataFromLocalStorage = () =>{
		setPreLoadObject(JSON.parse(localStorage.getItem('preLoadDataJson')));
	}
	const closePopup = (event) =>{
		setOpenColor(event);
		setOpenSize(event);
		setOpenGlazing(event);
	}
	const colorUpdated = (event) =>{
		setColorUp(event);
	}

	const [searchParams, setSearchParams] = useSearchParams();
	var product = searchParams.get("style");
	var color = searchParams.get("colorName");
	const loadDefaultValue = async () => {
		let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
		let res = await fetch(API_URL + `load-default-value?product-name=${product}&color-name=${color}`);
		let json = await res.json();
		let imageSource = '';
		if(preLoadObject){
			if (preLoadObject.hasOwnProperty('colorImage')) {
				setCanvasImage(preLoadObject?.colorImage);
				imageSource = preLoadObject?.colorImage;
			} else {
				setCanvasImage(json?.[0]?.image);
				imageSource = json?.[0]?.image;
			}
		}
		else{
			setCanvasImage(json?.[0]?.image);
			imageSource = json?.[0]?.image;
		}
		setTechaspect(json?.[0]?.technicalAspects);
		initLocalStorage(json?.[0]);
		return imageSource;
	  };

	const cartButtonClick = () =>{
		let personInfo = JSON.parse(localStorage.getItem('userInfo'));	
		if(personInfo){
			if (personInfo.hasOwnProperty('name') || personInfo.hasOwnProperty('mobileNumber') || personInfo.hasOwnProperty('email'))
			{
				navigate('/cart');
			}
			else{
				setOpen(true);
			}
		}
		else{
			setOpen(true);
		}
		let productJson = JSON.parse(localStorage.getItem('cartItem'));
		if(productJson == undefined) productJson = {"products": []};
		let productsOld = productJson["products"] != null ? productJson["products"] : [];
		let products = productsOld.filter((product) => {
			return productsOld["productName"] != product;
		});
		let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
		const updatedPreLoadObject = {'productName':product, ...preLoadObject};
		products.push(updatedPreLoadObject);
		const updatedProductsJson = {products};
		localStorage.setItem('cartItem',JSON.stringify(updatedProductsJson));
		localStorage.removeItem('preLoadDataJson');
	} 

	const correctColorName = (colorName) =>{
		if(colorName == 'golden-oak'){
			return 'Golden Oak';
		}
		if(colorName == 'red-oak'){
			return 'Red Oak';
		}
		if(colorName == 'dark-oak'){
			return 'Dark Oak';
		}
		if(colorName == 'milk-white'){
			return 'Milk White';
		}
		if(colorName == 'dark-grey-metal'){
			return 'Dark Grey Metal';
		}
		if(colorName == 'dark-grey-metal-laminated'){
			return 'Dark Grey Metal Laminated';
		}
		if(colorName == 'jet-black'){
			return 'Jet Black';
		}
		if(colorName == 'jet-black-laminated'){
			return 'Jet Black Laminated';
		}
		if(colorName == 'walnut'){
			return 'Walnut';
		}
	}

	const initLocalStorage = (preLoad) =>{
		let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
		const updatedPreLoadObject = {
			...preLoadObject,
			  height: preLoad?.defaultHeight,
			  width: preLoad?.defaultHeight,
			  colorImage: preLoad?.image,
			  colorName: preLoad?.colorSlug ? correctColorName(preLoad?.colorSlug) : DEFAULT_COLOR_NAME,
			  colorSlug: preLoad?.colorSlug ? preLoad.colorSlug : DEFAULT_COLOR_SLUG,
			  colorPrice: preLoad?.colorPrice ? preLoad.colorPrice : 0,
			  glazingName: DEFAULT_GLAZING_NAME,
			  glazingSlug: DEFAULT_GLAZING_SLUG,
			  glazingPrice: 0,
			  glazingThickness: DEFAULT_GLAZING_THICKNESS,
			  productName: preLoad?.productName,
			  technicalAspects: preLoad?.technicalAspects,
			  categoryName: preLoad?.categoryName,
			  categoryType: preLoad?.categoryType,
			  quantity:'1',
			  basePrice: preLoad?.basePrice,
			  styleName: preLoad?.styleName
		  };
		  if(preLoadObject === null){
			  localStorage.setItem('preLoadDataJson', JSON.stringify(updatedPreLoadObject));
		  }
	}



	useEffect(() => {
		const loadData = async () => {
			try {
				await loadDataFromLocalStorage();
				const defaultValue = await loadDefaultValue();
				if(defaultValue){
					const canvas = canvasRef.current;
					if (canvas) {
						const ctx = canvas.getContext("2d");
						const img = new Image();
						img.src = defaultValue;
						setCanvasImage(defaultValue);
						img.onload = () => {
						ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
						};
					}
				}
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		};
    	loadData();
		setIsLoading(true);
	}, [canvasImage,colorUp,openGlazing,openSize]);

	return (
	    <>
	    	<Header />
			<BackButton />
			<div className='container-fluid container-lg'>
				<div className='mt-5 text-center'>
					<h2>Customize your product</h2>
				</div>
				<div className="row">
					<div className="col-sm-5 col-lg-5 left-column px-lg-5 text-center">
						<canvas ref={canvasRef} width={400} height={400}></canvas>
					</div>
					{isLoading ? (
					<>
					<div className="col-sm-7 col-lg-4 col-xs-12 left-column p-5">
						<h5>{returnNullIfEmpty(preLoadObject?.styleName)}</h5>
						<h6>{returnNullIfEmpty(preLoadObject?.width) + ' X ' + returnNullIfEmpty(preLoadObject?.height) + ' | ' + returnNullIfEmpty(preLoadObject?.colorName) + ' | ' + returnNullIfEmpty(preLoadObject?.glazingName) + ' | ' + returnNullIfEmpty(preLoadObject?.glazingThickness)}</h6>
						<hr></hr>
					{<p className='mt-3 genral-string' dangerouslySetInnerHTML={{__html: techaspect}} />}
					</div>
					<div className="col-sm-12 col-lg-3 col-xs-12 right-column py-lg-5 pb-sm-3">
						<div className="home-page-button-parent">
							<div className="options-table">
								<div className='row'>
									<div className='col-sm-12 col-md-12  col-xl-6 d-flex d-lg-block justify-content-around'>
										<div className="">
											<button className="options-button" onClick={()=>{setOpenSize(true)}}><span className='icon-container'><BsNodePlus /></span>&nbsp;&nbsp;Size</button>
										</div>
										<div className="">
											<button className="options-button" onClick={()=>{setOpenColor(true); setColorUp(0)}}><span className='icon-container'><BsFillPaletteFill /></span>&nbsp;&nbsp;Color</button>
										</div>
										<div className="">
											<button className="options-button" onClick={()=>{setOpenGlazing(true)}}><span className='icon-container'><BsMicrosoft /></span>&nbsp;&nbsp;Glass Type</button>
										</div>
										<div className="pt-lg-5">
											<button style={{paddingLeft:10}} className="options-button" onClick={cartButtonClick}><BsFillCartPlusFill />&nbsp;&nbsp;Add to Cart</button>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					</>
					) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
	      		</div>
		  	</div> 
			<Popup lockScroll={true} open={openSize} modal closeOnDocumentClick={false}>
				<button className="close float-right" onClick={() => {setOpenSize(false)}}>&times;</button>
				<Size closePopup={closePopup} />
			</Popup>
			<Popup lockScroll={true} className='parent-for-color-popup' open={openColor} modal closeOnDocumentClick={false}>
				<button className="close float-right" onClick={() => {setOpenColor(false)}}>&times;</button>
				<Color closePopup={closePopup} colorUpdated={colorUpdated} />
			</Popup>
			<Popup lockScroll={true} className='parent-for-glazing-popup' open={openGlazing} modal closeOnDocumentClick={false}>
				<button className="close float-right" onClick={() => {setOpenGlazing(false)}}>&times;</button>
				<Glazing closePopup={closePopup} />
			</Popup>
			<Popup className="model-popup-with-form" open={open} modal nested closeOnDocumentClick={false}>
				<button className="close float-right" onClick={() => {setOpen(false)}}>&times;</button>
				<form>
					<div className="row">
						<div className="col-md-12 px-5 py-3">
							<div className={`form-group ${errors.name ? 'has-error' : ''}`}>
								<label htmlFor="usr">Name</label>
								<input type="text" className={`form-control ${errors.name ? 'error-input' : ''}`} value={name} onFocus={(event) => setErrors(prevErrors => ({ ...prevErrors, name: '' }))} onChange={(event) => setName(event.target.value)}
								/>
								{errors.name && <div className="error-message">{errors.name}</div>}
							</div>
						</div>
						<div className="col-md-12 px-5 py-3">
							<div className={`form-group ${errors.mobileNumber ? 'has-error' : ''}`}>
								<label htmlFor="usr">Mobile</label>
								<input type="text" className={`form-control ${errors.mobileNumber ? 'error-input' : ''}`} value={mobileNumber}  onFocus={(event) => setErrors(prevErrors => ({ ...prevErrors, mobileNumber: '' }))} onChange={(event)=>{setMobileNumber(event.target.value);}}/>
								{errors.mobileNumber && <div className="error-message">{errors.mobileNumber}</div>}
							</div>
						</div>
						<div className="col-md-12 px-5 py-3">
							<div className={`form-group ${errors.email ? 'has-error' : ''}`}>
								<label htmlFor="usr">Email</label>
								<input type="text" className={`form-control ${errors.email ? 'error-input' : ''}`} value={email}  onFocus={(event) => setErrors(prevErrors => ({ ...prevErrors, email: '' }))} onChange={(event)=>{setEmail(event.target.value);}}/>
								{errors.email && <div className="error-message">{errors.email}</div>}
							</div>
						</div>
					</div>
					<div className="col-md-12 pb-5 text-center">
						<button className="gf-button mt-3 w-50" onClick={submitForm}>Submit</button>
					</div>
				</form>
			</Popup>
		<Footer />
	</>);
}


export default Home;