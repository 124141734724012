import { BsCartFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
function Header() {
  return (
    <>
        <div className='header bg-light p-2 d-flex'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-3 col-sm-3 col-10 image-column'>
                        <Link to={{ pathname: "/"}}>
                        <img src={require('./assets/logo.png')} className={'img-fluid'}/>
                        </Link>
                    </div>
                    <div className='col-6 col-sm-4 title-column'>
                        <h3 className="text-center title">Price Estimator</h3>
                    </div>
                    <div className='col-lg-2 col-sm-4 col-10 contact-info-column'>
                        <div className='header-content'>
                            <h5 className='blue-text'>Contact</h5>
                            <a href='tel:+91 9959083498' className='contact-link'><span>+91 9959083498</span></a>
                        </div>
                    </div>
                    <div className='col-lg-1 col-sm-1 col-1 cart-button-column'>
                        <div className="cart-link-parent">
                            <Link to={{ pathname: "/cart"}} className='cart-link-header-button'><BsCartFill /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Header;
