import ReactLoading from 'react-loading';
import 'reactjs-popup/dist/index.css';
import React, { useState,useEffect } from 'react';

import Header from './Header';
import Footer from './Footer';
import { API_URL, WEBSITE_URL } from './Constant';
import { Link,createSearchParams } from 'react-router-dom';


function Select() {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState();
	const [isLandscape, setIsLandscape] = useState(true);

	const loadCards = () => {
		fetch(API_URL+"load-select")
				.then((res) => res.json())
				.then((json) => {
					setData(json);
				})
	}

	useEffect(() => {
		setIsLoading(false);
	
		const checkOrientation = () => {

			setIsLandscape(window.matchMedia(("(orientation: landscape)")).matches);
			console.log(window.matchMedia(("(orientation: landscape)")).matches);
			// setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
		};
	
		checkOrientation(); // Initial check
		window.addEventListener('orientationchange', checkOrientation);
	
		loadCards();
		localStorage.removeItem('preLoadDataJson');
		setIsLoading(true);
	
		return () => {
			window.removeEventListener('orientationchange', checkOrientation);
		};
	}, []);

	return(
		<>
			<Header />
			<div className={`row my-auto mx-auto text-center select-component ${isLoading ? '' : 'full-height'}`}>
			  { isLoading ? (
				<>
				{ !isLandscape ? (
					<div className='responsive-message-block d-flex'>
						<h1>Please change Orientation to landscape</h1>
					</div>
				): null}
				
      			<div className="col-md-12 col-xs-12 col-sm-12 col-12 col-lg-12 mx-auto my-auto">
					<h1 className='title-parent'>Get Price of your <span className='title'>Dream</span></h1>
						<div className="row card-screen gx-3 mx-auto d-flex justify-content-center">
							{data?.map((item, index) => (
								<div className="col-md-6 col-lg-3 col-xs-12" key={'item:'+index}>
									<div className='item my-4 custom-card'>
										<div className="window-option-image">
											<img src={item.image} className='img-fluid'/>
										</div>
										<div className='text-center'>
											<h3 className="window-option-text mb-4">{item.name}</h3>
											<Link to={{ pathname: "/cards", search: `?${createSearchParams({ card: item.id })}` }} className='gf-button'>Get Price Now</Link>
										</div>
									</div>
								</div>
							))}
						</div>
					<a href={WEBSITE_URL} target='_blank' className='btn btn-danger button-bottom'>Back to Website</a>
      			</div></> ) :  <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' /> }
      		</div> 
			<Footer />
		</>
	);
}

export default Select;