import React, {useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Header from '../Header';
import Footer from '../Footer';
import { BASE_PRICE } from '../Constant';
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsSend } from "react-icons/bs";
import { BsFillArrowLeftSquareFill } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';

import 'reactjs-popup/dist/index.css';

function Quote() {
	const navigate = useNavigate();
	const [isLoading , setIsLoading] = useState();
    const [cartItemsInInvoice, setCartItemsInInvoice] = useState();
    const [userInfo, setUserInfo] = useState();


    function countPrize(item,squareft,colorPrice,glazingPrice){

            var price = parseFloat(item.basePrice) || BASE_PRICE;
			if (item.hasOwnProperty('colorPrice')) {
                if(item.colorPrice > 0){
                    price = price * parseFloat(colorPrice);
                }
			}
            if (item.hasOwnProperty('glazingPrice')) {
                if(item.glazingPrice > 0){
                    price = price + parseFloat(glazingPrice);
                }
			}
            return parseFloat(price*squareft).toFixed(0);
    }

    function mmtosqft(width,height){
            let widthDevided = width / 1000;
            let heightDevided = height / 1000;
            let area_sq_m = heightDevided * widthDevided;
            let area_sq_ft = area_sq_m / 0.092903;
            return area_sq_ft.toFixed(2); 
    }

	const loadCalculations = () => {
		let cartItems = JSON.parse(localStorage.getItem('cartItem'));
        setUserInfo(JSON.parse(localStorage.getItem('userInfo')));
        setCartItemsInInvoice(cartItems);
		setIsLoading(true);
	}

    const estimatePageBackButtonCall = () =>{
        navigate('/cart');
    }

    const printAndDownloadButton = () => {
        document.title = 'green_fortune_quote';
        window.print();
    }


	useEffect(() => {
		loadCalculations();
	}, []);
	return(
		<>  
            <Header />
            <button id="backButton" className="back-button" onClick={estimatePageBackButtonCall}><BsFillArrowLeftSquareFill /> Back</button>
      		<div className="container-fluid">
      			<div className="row">
      				<div className="col-12">
      					<div className="card special-desktop-margin">
      					<div className="card-header hide-for-print">
      						<div className="row">
	      						<div className="d-flex col-8 col-lg-10">
	      							<h1>Price Estimator</h1>
	      						</div>
	      						<div className="col-4 col-lg-2">
	      							<button id="printButton" onClick={printAndDownloadButton} className="btn btn-danger float-right my-2">Print & Download</button>
	      						</div>
	      					</div>
      					</div>
                        <div className='card-body print-content' id='print-content'>
                            <div className='row onlyPrint'>
                                <div className='col-8 text-center'>
                                    <h1>Price Estimate</h1>
                                </div>
                                <div className='col-4 text-center'>
                                <img src={require('../assets/logo.png')} className='img-fluid' style={{width:300}}/>
                                </div>
                            </div>
                            <p>
                            Dear {userInfo?.name},<br /><br />
                            It gives us immense pleasure to share the “fair price estimate” for your GreenFortune UPVC Windows and Doors.
As one of India's fastest growing uPVC brands, we strive to offer high quality products at affordable prices.</p>
<br/>
<b>Please note below:</b>
<ol>
    <li>
    This is a fair price estimate only and does not constitute a quotation. The fair price estimate is to be used for
reference when discussing the final price for your customized windows and doors. GST will be applicable over
and above the fair price
    </li>
    <li>
    The fair price estimate also gives you a reference specification for the said price. The final price may increase or
decrease marginally basis final specification
    </li>
    <li>
    The fair price estimate assumes a survival margin for fabricators already included. A final price which is very
deviant from the fair price may indicate undesirable quality compromise. We strongly encourage customers to
take note and fully understand specifications before finalizing a price. Warranty will be void if appropriate
specifications are not maintained
    </li>
    <li>
    We actively encourage customers to reach out to our technical services <a href="#contact-info"> for any clarifications or specification
validation</a>
    </li>
    <li>
    Final terms and conditions including delivery terms and payment terms are to be agreed between fabricator and
customer. GreenFortune facilitates the order but is in no way liable for disputes between fabricators and
customers
    </li>
</ol>
{isLoading ? (
    <table className='table table-bordered'>
        <thead>
            <tr>
                <td>#</td>
                <td>Item</td>
                <td>Specifications</td>
                <td>Area</td>
                <td>Quantity</td>
                <td>Unit Price</td>
                <td>Total</td>
            </tr>
        </thead>
        <tbody>
            {cartItemsInInvoice?.products?.map((item, index) => (
            <tr key={'item:'+index}>
                <td>{index+1}</td>
                <td><img src={item?.colorImage ?? item.image} className='img-fluid' width={200} /></td>
                <td className="table-parent"><table className='table table-in-table'>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{item.productName}</td>
                            </tr>
                            <tr>
                                <td>Dimensions</td>
                                <td>{item?.width} X {item.height} mm</td>
                            </tr>
                            <tr>
                                <td>Frame Color</td>
                                <td>{item?.colorName}</td>
                            </tr>
                            <tr>
                                <td>Glazing</td>
                                <td>{item?.glazingName}</td>
                            </tr>
                            <tr>
                                <td>Glazing Thikness</td>
                                <td>{item?.glazingThickness}</td>
                            </tr>
                            <tr>
                                <td>Technical Aspects</td>
                                {<td dangerouslySetInnerHTML={{__html: item.technicalAspects}} />}
                            </tr>
                        </tbody>
                    </table></td>
                <td>{mmtosqft(item.width,item.height)}</td>
                <td>{item?.quantity}</td>
                <td>{countPrize(item,mmtosqft(item.width,item.height),item.colorPrice,item.glazingPrice)}</td>
                <td>{countPrize(item,mmtosqft(item.width,item.height),item.colorPrice,item.glazingPrice)*item?.quantity}</td>
            </tr>
            ))}
        </tbody>
</table> ) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}   
                            <div className='term-and-condition mx-3'>
                                <b>Also read,</b>
                                <ul>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>How to select a UPVC window and door fabricator?</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>How to assess specifications or compare quotations?</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>DOs and Don’ts when undertaking a UPVC window and door project.</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>What is the importance of reinforcement in a UPVC Window or Door.</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>What is the importance of hardware in a UPVC Window or Door.</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>How to check if a UPVC window and door installation is done correctly?</a></li>
                                    <li><a href="https://thegreenfortune.com" target='_blank'>How can GreenFortune support you in your UPVC window and Door project?</a></li>
                                </ul>
                                We look forward to serving you in making your UPVC Window and Door project a success. Thank you
                                <br /><br /><br />
                                <div id='contact-info'>
                                    Yours Sincerely,<br/>
                                    GreenFortune Technical Services Team<br/>
                                    <BsFillTelephoneFill /> <a href="tel:9908619666">+91 9908619666</a><br/>
                                    <BsEnvelopeFill /> <a href="info@thegreenfortune.com"> info@thegreenfortune.com</a><br/>
                                    <BsSend /> <a href="https://thegreenfortune.com"> https://thegreenfortune.com</a><br/>
                                </div>
                            </div>
                            </div>
      					</div>
      				</div>
      			</div>
      		</div>
            <Footer />
		</>
	);
}

export default Quote;