import { useNavigate,useSearchParams } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import DOMPurify from 'dompurify';
import { BsCheckSquareFill } from "react-icons/bs";
import { BsFillXSquareFill } from "react-icons/bs";

import Header from '../Header';
import Footer from '../Footer';
import BackButton from '../BackButton';
import { API_URL } from '../Constant';

function Design(props) {

  const navigate = useNavigate();
  const [data , setData] = useState();
  const [productStyle, setProductStyle] = useState();
  const [selectedItem, setSelectedItem] = useState(0);
  const [canvasImage, setCanvasImage] = useState();
  const [styleName, setStyleName] = useState();
  const canvasRef = useRef();
  const saveStyle = () =>{
    navigate({
      pathname: '/home',search: `?style=${productStyle}`});
  }

  const selectStyle = (e) =>{
    setSelectedItem(e.currentTarget.getAttribute("data-index"));
    setProductStyle(e.currentTarget.getAttribute("data-style"));
    
    setStyleName(data[e.currentTarget.getAttribute("data-index")]?.styleName);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = e.currentTarget.getAttribute("data-image");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let category = searchParams.get("card")


  const loadCards = async ()  => {
        let res = await fetch(API_URL+`get-product-from-specific-category?product_category_name=${category}`);
        let json = await res.json();
        setData(json);
        setStyleName(json[0]?.styleName);
        let imageSource;
        if(productStyle == '' || productStyle == undefined){
          setProductStyle(json[0]?.slug);
        }
        setCanvasImage(json[0]?.image);
        imageSource = json[0]?.image;
        return imageSource;
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const defaultValue = await loadCards(); // Assuming this function sets canvasImage
        if(defaultValue){
          setCanvasImage(defaultValue);
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.src = defaultValue;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
      } catch (error) {
        console.error(error);
      }
    };
      loadData();
  }, [canvasImage]);

  return (
    <>
      <Header />
      <BackButton />
        <div className='container'>
          <div className="row design-screen">
            <div className="col-sm-6 col-lg-4 col-12 left-column p-lg-5">
              <canvas ref={canvasRef} width={400} height={400}></canvas>
            </div>
            {data ? (
              <>
            <div className="col-lg-8 col-sm-6 col-12 p-lg-5">
              <h1 className='design-page-title'>{styleName}</h1>
              <div className="parent-div">
                <div className="row">
                  {data?.map((item, index) => (
                    <div className="col" key={index}>
                      <div className={`item ${selectedItem == index ? 'selected' : ''}`} data-style={item.slug} data-index={index} data-image={item.image} onClick={selectStyle}>
                        <div className="window-option-image">
                          <img src={item.image} alt={item.name} />
                        </div>
                        <div className="window-option-text">{item.name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-sm-12 d-none d-lg-block'>
               { <div className='mt-5' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[selectedItem]?.content) }} /> }
             </div>
              </div>
               <div className='col-sm-12 d-sm-block d-md-none '>
               { <div className='mt-5' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[selectedItem]?.content) }} /> }
             </div>
             <div className='col-6 col-lg-3 d-flex offset-md-5 mb-5'>
              <button className="confirmation-button options-button" onClick={saveStyle}><BsCheckSquareFill />&nbsp;&nbsp;&nbsp;Proceed</button>
              <button className="cancel-button options-button" onClick={()=>{navigate('/')}}><BsFillXSquareFill />&nbsp;&nbsp;&nbsp;Cancel</button>
              </div>
              </>) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
          </div>
        </div>
      <Footer />
    </>
  );
}


export default Design;