import ReactLoading from 'react-loading';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../BackButton';
import Header from '../Header';
import Footer from '../Footer';
import { BsFillTrashFill } from "react-icons/bs";
import { BsFillArrowLeftSquareFill } from 'react-icons/bs';

function Cart() {

	const navigate = useNavigate();
	const [cartItems, setCartItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const loadCartDetails = () => {

		let tempData = JSON.parse(localStorage.getItem('cartItem'));
		setCartItems(tempData);
		return tempData;
	}

	const handleQuantityChange = (event, productIndex) => {
		const updatedCartItems = cartItems?.products?.map((item,index) => {
		  if ( index === productIndex) {
			return {
			  ...item,
			  quantity: event.target.value,
			};
		  }
		  return item;
		});
		setCartItems({ ...cartItems, products: updatedCartItems });
		localStorage.setItem('cartItem', JSON.stringify({ products: updatedCartItems }));
	  };


	  const handleRemoveFromCartButton = (event, productIndex) => {
		const updatedCartItems = cartItems?.products?.filter((item, index) => index !== productIndex);
	  
		setCartItems({ ...cartItems, products: updatedCartItems });
		localStorage.setItem('cartItem', JSON.stringify({ products: updatedCartItems }));
	  };

	const quoteButton = (e) =>{
		navigate('/price-estimate');
	}

	useEffect(() => {
		const loadData = async () => {
		  try {

			const defaultValue = await loadCartDetails();
			
		  } catch (error) {
			console.error(error);
		  }
		};
		  loadData();
		  setIsLoading(true);
	  }, []);

	
	function mmtosqft(width,height){
		let widthDevided = width / 1000;
		let heightDevided = height / 1000;
		let area_sq_m = heightDevided * widthDevided;
		let area_sq_ft = area_sq_m / 0.092903;
		return area_sq_ft.toFixed(2); 
	}

	const cartPageBackButtonCall = () =>{
        navigate('/');
    }

	return(
		<>
			<Header />
			<button id="backButton" className="back-button" onClick={cartPageBackButtonCall}><BsFillArrowLeftSquareFill /> Back to Homepage</button>
			{isLoading ? (	
      		<div className="container-fluid">
      			<div className="row bg-gray-200">
					<div className="col-12 col-lg-10 col-xs-12 cart-page-bottom-margin">
					{cartItems?.products?.length > 0 ? (
						cartItems.products.map((item, index) => (
							<div className="card" key={'item:'+index}>
							<div className="row bg-light justify-content-start">
								<div className="col-5 col-lg-4 col-xs-12 text-center mt-5">
									<img src={item?.colorImage ?? item.image } width={500} className='img-fluid text-center' />
									<button className='btn btn-danger product-remove-button' onClick={(event) => handleRemoveFromCartButton(event, index)}><BsFillTrashFill /> Remove From Cart</button>
								</div>
								<div className="col-6 col-lg-4 col-xs-12 bg-white">
									<h1 className="my-4">{item.productName}</h1>
									<table className="cart-data-table table">
										<tbody>
											<tr>
												<td>Product</td>
												<td>{item?.productName} | {item?.styleName}</td>
											</tr>
											<tr>
												<td>Dimensions</td>
	      										<td>{item?.width ?? item.defaultWidth} X {item?.height ?? item.defaultHeight}mm ( W X H)</td>
	      									</tr>
											<tr>
												<td>Unit Area</td>
												<td>{mmtosqft(item?.width??item.defaultWidth,item?.height??item.defaultHeight)} Sq. Ft.</td>
											</tr>
											<tr>
												<td>Frame Color</td>
												<td>{item?.colorName}</td>
											</tr>
											<tr>
												<td>Glazing</td>
												<td>{item?.glazingName}</td>
											</tr>
											<tr>
												<td>Glazing Thickness</td>
												<td>{item?.glazingThickness}</td>
											</tr>
											<tr>
												<td>Quantity</td>
												<td>
													<select className="form-select" value={item.quantity} onChange={(event) => handleQuantityChange(event, index)}>
														<option value="1">1 Unit</option>
														<option value="2">2 Units</option>
														<option value="3">3 Units</option>
														<option value="4">4 Units</option>
														<option value="5">5 Units</option>
														<option value="6">6 Units</option>
														<option value="7">7 Units</option>
														<option value="8">8 Units</option>
														<option value="9">9 Units</option>
														<option value="10">10 Units</option>
													</select>
												</td>
											</tr>
	      								</tbody>
      								</table>
      							</div>
								<div className="col-lg-4 mt-5">
									{<p className='mt-lg-5' dangerouslySetInnerHTML={{__html: item.technicalAspects}} />}
								</div>
      						</div>
						</div>
						))
						) : (
						<div className='empty-result'>No items in the cart</div>
						)}
      				</div>
      				<div className="col-12 col-lg-2 col-xs-12 bg-dark cart-page-sidebar">
      					<p className="text-light mt-5 p-3">You have successfully added your design to the cart. Get your fair price estimate now or Add more designs</p>
      					<button className="btn btn-success w-100" onClick={quoteButton} disabled={cartItems?.products?.length > 0 ? false : true} >Get Price Estimate Now</button>
      					<button className="btn btn-primary w-100 mt-2" onClick={()=>{navigate('/')}}>Add another Design</button>
      				</div>
      			</div>
      		</div>	) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
			<Footer />
		</>
	);
}

export default Cart;