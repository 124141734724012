import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'reactjs-popup/dist/index.css';

import Home from './Home';

import Design from './views/Design';
import Color from './views/Color';
import Glazing from './views/Glazing';
import Size from './views/Size';
import Cart from './views/Cart';
import Select from './Select';
import Cards from './Cards';
import Quote from './views/Quote';
import LinkMaker from './views/LinkMaker';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <Routes>
          <Route exact path="/" element={<Select/>} />
          <Route exact path="/cards" element={<Cards/>} />
          <Route exact path="/home" element={<Home/>}/>
          <Route exact path="/design" element={<Design/>}/>
          <Route exact path="/color" element={<Color/>}/>
          <Route exact path="/glazing" element={<Glazing/>}/>
          <Route exact path="/cart" element={<Cart/>}/>
          <Route exact path="/size" element={<Size/>}/>
          <Route exact path="/price-estimate" element={<Quote/>}/>
          <Route exact path="/link-maker" element={<LinkMaker />}/>
      </Routes>
    </div>
  );
}

export default App;
