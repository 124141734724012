import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';
import { API_URL } from './Constant';

import { Link, useSearchParams,createSearchParams } from 'react-router-dom';

function Cards() {
	const [data , setData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		loadCards();
		setIsLoading(true);
	}, []);
	const [searchParams, setSearchParams] = useSearchParams();
	let category = searchParams.get("card");
	const loadCards = async () => {
        let res = await fetch(API_URL+`card-category?parent_category_name=${category}`);
		let json = await res.json();
		setData(json);	
	}
	return(
		<>
			<Header />
			<BackButton />
      		<div className="container">
				{ isLoading ? (
      			<div className="row card-screen mt-5 mt-md-0 gx-3 d-flex justify-content-center">
					{data?.map((item, index ) => (
						<div className="col-md-6 col-lg-4 col-xs-12" key={'item:'+index}>
							<div className='item my-4 custom-card'>
								<div className="window-option-image">
									<img src={item.image} className='img-fluid'/>
								</div>
								<div className='text-center'>
									<h3 className="window-option-text mb-4" dangerouslySetInnerHTML={{ __html: item.name }}></h3>
									<Link to={{ pathname: "/design", search: `?${createSearchParams({ card: item.slug })}` }} className='gf-button'>Get Price Now</Link>
								</div>
							</div>
						</div>
					))}
      			</div>
				) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' /> }
      		</div>
			<Footer />
		</>
	);

}





export default Cards;