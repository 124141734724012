export const API_URL="https://tgfpe.cultnerds.io/wp-json/wl/v1/";
// export const API_URL="http://gf-frontend.local/wp-json/wl/v1/";
export const WEBSITE_URL="https://thegreenfortune.com/";
export const BASE_PRICE="600";
export const DEFAULT_COLOR_NAME="Golden Oak";
export const DEFAULT_COLOR_SLUG="golden-oak";
export const DEFAULT_GLAZING_NAME = 'Plain';
export const DEFAULT_GLAZING_SLUG="plain";
export const DEFAULT_GLAZING_THICKNESS="5mm";

