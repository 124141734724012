import React, { useRef, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BsFillPaletteFill } from 'react-icons/bs';
import { BsCheckSquareFill } from "react-icons/bs";
import { BsFillXSquareFill } from "react-icons/bs";
import ReactLoading from 'react-loading';
import DOMPurify from 'dompurify';
import { API_URL,DEFAULT_COLOR_SLUG,DEFAULT_COLOR_NAME } from '../Constant';


function Color(props) {
const canvasRef = useRef();
const [color, setColor] = useState();
const [colorName, setColorName] = useState(DEFAULT_COLOR_NAME);
const [canvasImage, setCanvasImage] = useState();
const [jsonData, setJsonData] = useState();
const [selectedIndex, setSelectedIndex] = useState();
const [colorPrice, setColorPrice] = useState();


const closePopup = () =>{
  props.closePopup(false);
}

const colorUp = () =>{
  props.colorUpdated(1);
}

const colorOption = (e) =>{
    setColorName(e.currentTarget.getAttribute("data-name"));
    setSelectedIndex(e.currentTarget.getAttribute("data-index"));
    setColorPrice(e.currentTarget.getAttribute("data-price"));
		const canvas = canvasRef.current;
		const ctx = canvas.getContext("2d");
		const img = new Image();
		img.src = e.currentTarget.getAttribute("data-color");

		img.onload = () => {
		  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
		};
    setColor(img.src);
}

const saveColorOption = () =>{
  let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
  const updatedPreLoadObject = {
    ...preLoadObject,
    colorImage: color,
    colorName: colorName,
    colorSlug: selectedIndex,
    colorPrice: colorPrice,
  };
  localStorage.setItem('preLoadDataJson', JSON.stringify(updatedPreLoadObject));
  closePopup();
  colorUp();
}

const [searchParams, setSearchParams] = useSearchParams();
let product = searchParams.get("style")


const loadDefaultValue = async (colorReturnValue) => {
  let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
  let imageSource = '';
  
  if(preLoadObject){
    console.log(preLoadObject,'preLoadObject');
    if (preLoadObject.hasOwnProperty('colorImage')) {
      setCanvasImage(preLoadObject?.colorImage);
      imageSource = preLoadObject?.colorImage;
      setColor(preLoadObject?.colorImage);
      setColorName(preLoadObject?.colorName);
      setSelectedIndex(preLoadObject?.colorSlug);
    }
    if(preLoadObject.hasOwnProperty('colorSlug')){
      setSelectedIndex(preLoadObject?.colorSlug);
    }
  }
  else{
    setCanvasImage(colorReturnValue[0]['image']);
    imageSource = colorReturnValue[0]['image'];
  }
  return imageSource;
};

const loadColorImageValue = async () =>{
  let res2 = await fetch(API_URL+`color-options-from-variation?product-name=${product}`);
  let jsonDatalocal = await res2.json();

  setJsonData(jsonDatalocal); 

  return jsonDatalocal;
}

useEffect(() => {
  const loadData = async () => {
    try {
      const colorReturnValue = await loadColorImageValue();
      const defaultValue = await loadDefaultValue(colorReturnValue); // Assuming this function sets canvasImage
      if(defaultValue){
        setCanvasImage(defaultValue);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = defaultValue;
        img.onload = () => {
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
      }
    } catch (error) {
      console.error(error);
    }
  };
    loadData();
}, [canvasImage]);

  return (
    <>
      <div className="container-fluid">
      
        <div className="row color-screen">
          <div className="col-7 col-lg-5 left-column p-lg-5 text-center">
          <canvas ref={canvasRef} className='model-popup-canvas'></canvas>
          </div>
          {jsonData ? (
          <div className="col-5 col-lg-7 right-column p-lg-5 add-scroller-for-mobile">
            <h1><BsFillPaletteFill />&nbsp;&nbsp;Color</h1>
            <div className="parent-div-color color-div-parent">
              <div className='color-type-heading'><h5>Solid Colors</h5></div>
              <div className='color-type-content'>
                {jsonData?.map((item, index) => {
                  if (item.name === 'Milk-White' || item.name === 'Jet Black' || item.name === 'Dark Grey Metal') {
                    return (
                      <div key={index} className={`item ${selectedIndex == item.slug ? 'selected' : ''}`} data-index={item.slug} data-name={item.name} data-color={item.image} data-price={item.price} onClick={colorOption}>
                        <div className="window-option-image">
                          <img src={item.icon} alt={item.name} />
                        </div>
                        <div className="window-option-text genral-string">{item.name}</div>
                      </div>
                    );
                  }
                })}
                </div>
            </div>
            <div className="parent-div-color color-div-parent">
              <div className='color-type-heading'><h5>Laminated Colors</h5></div>
              <div className='color-type-content'>
                {jsonData?.map((item, index) => {
                  if (item.name !== 'Milk-White' && item.name !== 'Jet Black' && item.name !== 'Dark Grey Metal') {
                    return (
                      <div key={index} className={`item ${selectedIndex == item.slug ? 'selected' : ''}`} data-index={item.slug} data-name={item.name} data-color={item.image} data-price={item.price} onClick={colorOption}>
                        <div className="window-option-image">
                          <img src={item.icon} alt={item.name} />
                        </div>
                        <div className="window-option-text genral-string">{item.name}</div>
                      </div>
                    );
                  }
                })}
                </div>
            </div>
          <div className='my-5'>
            { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jsonData[selectedIndex]?.description) }} /> }
          </div>
            <button className="confirmation-button" onClick={saveColorOption}><BsCheckSquareFill />&nbsp;&nbsp;&nbsp;Save</button>
            <button className="confirmation-button" onClick={closePopup}><BsFillXSquareFill />&nbsp;&nbsp;&nbsp;Cancel</button>
          </div>) :
        <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
        </div>
      </div>
    </>
  );
}

export default Color;