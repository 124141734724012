
import { BsFillTelephoneFill } from 'react-icons/bs';

import { BsEnvelopeFill } from 'react-icons/bs';

import { BsFillPinFill } from 'react-icons/bs';

import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";

function Footer() {
    return (
      <>
      <div className='footer bg-light'>
        <div className='footer-content'>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 first-column col-sm-12">
                <img src={require('./assets/logo.png')} className='img-fluid' style={{width:150}}/>
                <p className='description'>Our products are developed with 25+ years of experience in the Construction Industry.</p>
                <h1 className="blue-heading">Got question? Call Us</h1>
                <p><BsFillTelephoneFill />&nbsp;&nbsp;&nbsp;&nbsp;+91 9959083498</p>
                <p><BsEnvelopeFill />&nbsp;&nbsp;&nbsp;&nbsp;info@thegreenfortune.com</p>
                <h1 className="blue-heading">Social Links</h1>
                <a href='https://www.facebook.com/the_greenfortune/'><FaFacebookSquare size={"2rem"} /></a>
                <a href='https://www.instagram.com/the_greenfortune/'><FaInstagramSquare size={"2rem" } /></a>
                <a href='https://www.linkedin.com/company/thegreenfortune/'><FaLinkedin size={"2rem"} /></a>
                <a href='https://twitter.com/the_greenfortune/'><FaTwitterSquare size={"2rem"} /></a>
                <a href='https://in.pinterest.com/TheGreenFortune/'><FaPinterestSquare size={"2rem"} /></a>
              </div>
              <div className="col-lg-2 second-column col-sm-12">
                <h1 className="blue-heading">Quick Links</h1>
                <ul>
                  <li><a href="https://thegreenfortune.com/">Home</a></li>
                  <li><a href="https://thegreenfortune.com/about/">About Us</a></li>
                  <li><a href="https://thegreenfortune.com/#oursolutions">Solutions</a></li>
                  <li><a href="https://thegreenfortune.com/sustainability/">Sustainability</a></li>
                  <li><a href="https://thegreenfortune.com/blog/">Blog</a></li>
                  <li><a href="https://thegreenfortune.com/media-mentions/">Media</a></li>
                  <li><a href="https://thegreenfortune.com/contact/">Contact Us</a></li>
                </ul>
              </div>
              <div className="col-lg-4 third-column col-sm-12">
                <h1 className="blue-heading">Get In Touch</h1>
                <div className='address-parent'>
                  <div className='address-icon'>
                    <BsFillPinFill />
                  </div>
                  <div className='address-detail'>
                  Traumbuild AndesInfra Solutions Pvt. Ltd<br />
5-35/189-A, 100 Feet Rd, 4th Floor, Vilax Prime Prashanti Nagar, I.E, Kukatpally, Hyderabad, Telangana 500072
                  </div>
                </div>
              </div>
            </div>
          </div>
            {/* <h5>Copyrights 2023 | Brought to life by <a className='footer-web-link' href="https:cultnerds.io" target='_blank'>CultNerds IT Solutions Pvt Ltd</a></h5> */}
        </div>
        <div className='end-credits text-center'>
          Copyright © Traumbuild Andesinfra Solutions Private Limited All Rights Reserved
          </div>
      </div>
      </>
    );
}


export default Footer;

