import React, { useRef, useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';


import { API_URL } from '../Constant';

function LinkMaker(props) {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const colorNameList = ['dark grey metal laminated','dark grey metal','golden oak','red oak','grey','milk white','dark oak','walnut','jet black laminated','jet black','dark'];
    var product = searchParams.get("style").toLowerCase();
    product = product.replace(/-/g, ' ');
    var check = searchParams.get("from");
    var colorName = searchParams.get("color");

   


    const loadDefaultValue = async () => {
        if(check == 'website'){
            colorNameList.forEach(color => {
                product = product.replace(color, '').trim();
            });
            var findProductNameApi = await fetch(API_URL+`find-product-name-from-url?product-name=${product}`);
            var styleName = await findProductNameApi.json();
            navigate("/home/?style="+styleName+"&colorName="+colorName);
        }
    }
    useEffect(() => { 
        localStorage.removeItem('preLoadDataJson');
        loadDefaultValue();
    },[]);

return(<>
   <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />
</>);
}


export default LinkMaker;