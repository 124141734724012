import React, { useRef, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BsNodePlus } from 'react-icons/bs';
import { BsCheckSquareFill } from "react-icons/bs";
import { BsFillXSquareFill } from "react-icons/bs";
import ReactLoading from 'react-loading';

import { API_URL } from '../Constant';


function Size(props) {

  const [data, setData] = useState();
  const [canvasImage, setCanvasImage] = useState();
	const [canvasWidth, setCanvasWidth] = useState();
	const [canvasHeight, setCanvasHeight] = useState();
  const canvasRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  let product = searchParams.get("style")

  const canvasWidthSet = async (event,min,max) => {
    const inputValueWidth = parseFloat(event.target.value);
    const minValueWidth = parseFloat(min);
    const maxValueWidth = parseFloat(max);
    const inputElement = event.target;
    if (inputValueWidth >= minValueWidth && inputValueWidth <= maxValueWidth) {
        setCanvasWidth(inputValueWidth);
        inputElement.style.border = '2px solid green';
    } else {
        if(inputValueWidth <= minValueWidth){
          setCanvasWidth(minValueWidth);
        }
        else if(inputValueWidth >= maxValueWidth){
          setCanvasWidth(maxValueWidth);
        }
        inputElement.style.border = '2px solid red';
    }
  }

  const canvasHeightSet = async (event,min,max) => {
    const inputValueHeight = parseFloat(event.target.value);
    const minValueHeight = parseFloat(min);
    const maxValueHeight = parseFloat(max);
    const inputElement = event.target;
    if (inputValueHeight >= minValueHeight && inputValueHeight <= maxValueHeight) {
      setCanvasHeight(inputValueHeight);
      inputElement.style.border = '2px solid green';
    } else {
      if(inputValueHeight <= minValueHeight){
        setCanvasHeight(minValueHeight);
      }
      else if(inputValueHeight >= maxValueHeight){
        setCanvasHeight(maxValueHeight);
      }
      inputElement.style.border = '2px solid red';
    }
  }

  const loadDefaultValue = async () => {

		let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
		let res = await fetch(API_URL+`load-default-size?product-name=${product}`);
  	let json = await res.json();
    setData(json);
    let imageSource = '';
		if(preLoadObject){
			if (preLoadObject.hasOwnProperty('width')) {
				setCanvasWidth(preLoadObject?.width);
			} else {
				setCanvasWidth(json?.[0]?.defaultWidth);
			}
		}
		else{
			setCanvasWidth(json?.[0]?.defaultWidth);
		}
		if(preLoadObject){
			if (preLoadObject.hasOwnProperty('height')) {
				setCanvasHeight(preLoadObject?.height);
			} else {
				setCanvasHeight(json?.[0]?.defaultHeight);
			}
		}
		else{
			setCanvasHeight(json?.[0]?.defaultHeight);
		}
		if(preLoadObject){
			if (preLoadObject.hasOwnProperty('colorImage')) {
				setCanvasImage(preLoadObject?.colorImage);
        imageSource = preLoadObject?.colorImage;
			} else {
				setCanvasImage(json?.[0]?.image);
        imageSource = json?.[0]?.image;
			}
		}
		else{
			setCanvasImage(json?.[0]?.image);
      imageSource = json?.[0]?.image;
		}
    return imageSource;
	};

  useEffect(() => {
    const loadData = async () => {
      try {
        const defaultValue = await loadDefaultValue(); // Assuming this function sets canvasImage
        if(defaultValue){
          setCanvasImage(defaultValue);
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.src = defaultValue;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
      } catch (error) {
        console.error(error);
      }
    };
      loadData();
  }, [canvasImage]);


  const closePopup = () =>{
    props.closePopup(false);
  }
  
  const saveSizeOption = () => {
    let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
    const updatedPreLoadObject = {
      ...preLoadObject,
      width: canvasWidth,
      height: canvasHeight,
    };
    localStorage.setItem('preLoadDataJson', JSON.stringify(updatedPreLoadObject));
    closePopup();
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row size-screen">
          <div className="col-7 col-lg-5 left-column p-lg-5 text-center">
            <canvas ref={canvasRef} className='model-popup-canvas'></canvas>
          </div>
          {data ? (
          <div className="col-5 col-lg-7 right-column p-lg-5">
            <h1><BsNodePlus />&nbsp;&nbsp;Size</h1>
            <div className="parent-div">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="usr" className="my-2 genral-string">Width ( in MM )</label>
                    <div className='d-flex'>
                      <input type="number" className="form-control w-50" defaultValue={canvasWidth} onChange={(event) => canvasWidthSet(event,data[0]?.minWidth,data[0]?.maxWidth)}/>
                      <span className='genral-string'>&nbsp;&nbsp;Min : {data[0]?.minWidth}mm | Max : {data[0]?.maxWidth}mm</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 my-4">
                  <div className="form-group">
                    <label htmlFor="usr" className="my-2 genral-string">Height ( in MM )</label>
                    <div className='d-flex'>
                      <input type="number" className="form-control w-50" defaultValue={canvasHeight} onChange={(event) => canvasHeightSet(event,data[0]?.minHeight,data[0]?.maxHeight)}/>
                      <span className='genral-string'>&nbsp;&nbsp;Min : {data[0]?.minHeight}mm | Max : {data[0]?.maxHeight}mm</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="confirmation-button" onClick={saveSizeOption}><BsCheckSquareFill />&nbsp;&nbsp;&nbsp;Save</button>
            <button className="confirmation-button" onClick={closePopup}><BsFillXSquareFill />&nbsp;&nbsp;&nbsp;Cancel</button>
          </div> ) : <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
        </div>
      </div>
    </>
  );
}


export default Size;