import { useSearchParams } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { BsMicrosoft } from 'react-icons/bs';
import { BsCheckSquareFill } from "react-icons/bs";
import { BsFillXSquareFill } from "react-icons/bs";
import DOMPurify from 'dompurify';
import { API_URL,DEFAULT_GLAZING_NAME,DEFAULT_GLAZING_SLUG } from '../Constant';


function Glazing(props) {
  const [canvasImage, setCanvasImage] = useState();
  const canvasRef = useRef();
  const [selectedGlazing, setSelectedGlazing] = useState(DEFAULT_GLAZING_NAME);
  const [isLoading, setIsLoading] = useState(false);
  const [glazingOptionsData, setGlazingOptionsData] = useState();
  const [glazingIndex,setGlazingIndex] = useState(0);
  const [glazingSlug,setGlazingSlug] = useState(DEFAULT_GLAZING_SLUG);
  const [glazingPrice,setGlazingPrice] = useState(0);

  const [selectedGlassThickness, setSelectedGlassThickness] = useState(null);


  const loadGlazingAndGlassValue = async () => {
		let res2 = await fetch(API_URL+`glazing-options-from-variation?product-name=${product}`);
  	let json2 = await res2.json();
    setGlazingOptionsData(json2);
    return json2;
  }
  const handleGlassThicknessChange = (event) => {
    setSelectedGlassThickness(event.target.value);
    setGlazingPrice(event.currentTarget.getAttribute("data-price"));
  };
  const glazingOption = (e) => {
    setSelectedGlazing(e.currentTarget.getAttribute("data-name"));
    setGlazingIndex(e.currentTarget.getAttribute("data-index"));
    setGlazingSlug(e.currentTarget.getAttribute("data-slug"));
    setSelectedGlassThickness(glazingOptionsData[e.currentTarget.getAttribute("data-index")]['glass-thickness'][0]);
  }
  
  const saveGlazingOption = () => {
    let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));
    const updatedPreLoadObject = { ...preLoadObject,'glazingName':selectedGlazing,'glazingThickness':selectedGlassThickness,'glazingSlug':glazingSlug, 'glazingIndex':glazingIndex,'glazingPrice':glazingPrice};
    localStorage.setItem('preLoadDataJson', JSON.stringify(updatedPreLoadObject));
    closePopup();
  }

  const [searchParams, setSearchParams] = useSearchParams();
  let product = searchParams.get("style")

  const loadDefaultValue = async (firstApi) => {
		let preLoadObject = JSON.parse(localStorage.getItem('preLoadDataJson'));

    let imageSource = '';
    if(preLoadObject){
			if (preLoadObject.hasOwnProperty('glazing')) {
        setSelectedGlazing(preLoadObject?.glazingName);
		  }
      if(preLoadObject.hasOwnProperty('glazingIndex')){
        setGlazingIndex(preLoadObject?.glazingIndex);
      }
      if(preLoadObject.hasOwnProperty('glazingSlug')){
        setGlazingSlug(preLoadObject?.glazingSlug);
      }
      if(preLoadObject.hasOwnProperty('glazingThickness')){
        if(preLoadObject?.glazingThickness == null || preLoadObject?.glazingThickness == '' || preLoadObject.glazingThickness == undefined){
            setSelectedGlassThickness(firstApi[glazingIndex]['glass-thickness'][0]);
        }
        else{
          setSelectedGlassThickness(preLoadObject?.glazingThickness);
        }
      }
    }
    else{
      setSelectedGlassThickness(firstApi[glazingIndex]['glass-thickness'][0]);
    }
		if(preLoadObject){
			if (preLoadObject.hasOwnProperty('colorImage')) {
				setCanvasImage(preLoadObject?.colorImage);
        imageSource = preLoadObject?.colorImage;
      }
      else{
        imageSource = preLoadObject?.image;
			}
    }
    return imageSource;
	};



  useEffect(() => {
    const loadData = async () => {
      try {
        const firstApi = await loadGlazingAndGlassValue();
        const defaultValue = await loadDefaultValue(firstApi);

        if(defaultValue){

          setCanvasImage(defaultValue);
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.src = defaultValue;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
          setIsLoading(true);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
      loadData();
  }, [canvasImage]);


  const closePopup = () =>{
    props.closePopup(false);
  }

  const handleLabelClick = (index) => {
    const inputId = `glassThickness_${index}`;
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
        inputElement.click();
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row glazzing-screen">
          <div className="col-7 col-lg-5 left-column p-lg-5 text-center">
            <canvas ref={canvasRef} className='model-popup-canvas'></canvas>
          </div>
          {isLoading ? (
          <div className="col-5 col-lg-7 right-column p-lg-5 add-scroller-for-mobile">
            <h1><BsMicrosoft />&nbsp;&nbsp;Glazing</h1>
            <div className="parent-div glazing-div-parent">
              {glazingOptionsData?.map((item, index) => (
                <div className={`item ${glazingSlug == item.slug ? 'selected' : ''} `} data-index={index} data-slug={item.slug}  key={item.name} data-name={item.name} onClick={glazingOption} >
                  <div className="window-option-image" key={item.index}>
                    <img src={item['glazing-image']} className='border-around' />
                  </div>
                  <div className="window-option-text">{item.name}</div>
                </div>
              ))}
            </div>
            <div className='my-5'>
              { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(glazingOptionsData[glazingIndex]?.description) }} /> }
            </div>
            <h1>Thickness</h1>
                <div className="glass-thickness-area d-flex">
                {glazingOptionsData[glazingIndex]['glass-thickness']?.map((item, index) => ( 
                      <div key={'item :'+index} className='glass-thickness-area-child'>
                          <input
            type="radio"
            id={`glassThickness_${index}`}
            name="glassThickness"
            value={item}
            checked={index === 0 ? true : selectedGlassThickness === item}
            data-price={glazingOptionsData[glazingIndex]['price'][index]}
            onChange={handleGlassThicknessChange}
        />
        <label htmlFor={`glassThickness_${index}`} onClick={() => handleLabelClick(index)}>
            {item}
        </label>
                      </div>
                  ))}
                </div>
            <button className="confirmation-button" onClick={saveGlazingOption}><BsCheckSquareFill />&nbsp;&nbsp;&nbsp;Save</button>
            <button className="confirmation-button" onClick={closePopup}><BsFillXSquareFill />&nbsp;&nbsp;&nbsp;Cancel</button>
          </div>
          ) :
          <ReactLoading type="bars" color="#1291a8" height={'5%'} width={'5%'} className='position-absolute top-50 start-50 translate-middle' />}
        </div>
      </div>
    </>
  );
}


export default Glazing;
