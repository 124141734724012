import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BsFillArrowLeftSquareFill } from 'react-icons/bs';

function BackButton() {
  const navigate = useNavigate();

 
  return (
    <button className="back-button" onClick={()=>{navigate(-1)}}><BsFillArrowLeftSquareFill /> Back</button>
  );
}

export default BackButton;